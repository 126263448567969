@import '../../assets/stylesheets/application';

@import '~simplemde/dist/simplemde.min';

body.rails_admin {
  .table tr.order_row {
    td.status_field {
      &[title=pending_canceling], &[title=pending_hold] {
        background-color: yellow;
      }
    }

    td.__state_field, td.origin_field {
      overflow: revert !important;
    }
  }

  .table tr.partner_finance_row {
    td.status_field {
      &[title=order_completed] {
        background-color: yellow;
      }
    }

    td.__origin_field {
      overflow: revert !important;
    }
  }

  .table-float {
    max-width: unset;
    min-width: 380px;
    width: unset;

    td {
      align-items: center;
      max-width: unset;
      text-align: center;
    }

    thead {
      background-color: #f8f8f8;
    }

    th {
      text-align: center;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
      max-width: 100px;
      text-align: center;
    }

    input[type=select] {
      min-width: 200px;
    }

    .filterable-column-title {
      .select2 {
        font-weight: normal;
        width: unset !important;
      }

      hr {
        margin-bottom: 5px;
        margin-top: 8px;
      }
    }

    .column-title {
      align-items: center;
      text-align: center;
    }

  }
}

.alert-import_log {
  display: none;
}

.card-container {
  margin: 0 auto;
  max-width: 500px;
}

#card-button {
  width: 100%;
}

#card-element {
  padding: 12px;
}

.order-state-dropdown {
  display: block;
}

.cm-s-800-height.CodeMirror {
  height: 800px !important;
}

.cm-s-600-height.CodeMirror {
  height: 600px !important;
}

.cm-s-500-height.CodeMirror {
  height: 500px !important;
}

.cm-s-400-height.CodeMirror {
  height: 400px !important;
}

.cm-s-300-height.CodeMirror {
  height: 300px !important;
}

.cm-s-200-height.CodeMirror {
  height: 200px !important;
}

.cm-s-100-height.CodeMirror {
  height: 100px !important;
}

.cm-s-dynamic-height.CodeMirror {
  height: unset !important;
}
