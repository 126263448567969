fieldset.check_list_box {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 12px;
  overflow-y: scroll;
  height: auto;
  max-height: 150px;
  width: max-content;
  min-width: 200px;

  label {
    font-weight: normal;
    margin-bottom: unset;
  }
}
